import React, { useReducer, useContext, useEffect } from 'react'
import { lockedReducer } from '../../Utils/reducers'
import { api } from '../../../services/api'
import ColorButton from '../../Buttons/ColorButton';
import DefaultModal from '../../Utils/DefaultModal';
import toast from 'react-hot-toast'
import { Box, Tooltip, TextField } from '@mui/material'
import validateData from './validateProducts';
import { externalComponent } from '../../AppRoutes';

import MegaAutoComplete from '../../Utils/megautocomplete';

const url = '/documento_produto/'

const submit = async (data, getData, setLoader, handleClose) => {

    setLoader(true)
    let res = null

    if (data.id)
        res = await api.patch(`${url}${data.id}/`, data)
    else
        res = await api.post(url, data)
    if (res.status === 201 || res.status === 200) {
        toast.success(`Produto ${res.status === 201 ? 'adicionado' : 'editado'} com sucesso`)
        getData()
        handleClose()
        setLoader(false)
        return
    }
    toast.error(`Produto não pôde ser ${!data.id ? 'adicionado' : 'editado.'} Motivo: ${res.data}`)
    setLoader(false)
}

const loadRetrieve = async (id, setState, setLoader) => {
    setLoader(true)
    const res = await api.get(url + `${id}/`)
    if (res.status === 200) {
        setState({ ...res.data})
    }
    setLoader(false)
}

const getTitle = (data) => {
    if (data?.id)

        return 'Editar'
    return 'Adicionar'
}

const EditProducts = ( {data, handleClose, reloadData,orderId} ) => {

    const { setLoader } = useContext(externalComponent)

    const [state, setState] = useReducer(lockedReducer, {
        order:orderId,
        product:'',
        price: '',
        amount: '',
        productdesc: '',
        id: data.id ? data.id : 0
    })

    const validData = validateData(state)

    useEffect(() => {
        if (data.id)
            loadRetrieve(data.id, setState, setLoader)
        else
            setState({ loaded: true })
    }, [setLoader, data.id])
    
    return(
        <>
        <DefaultModal
                title={
                    `${getTitle(state)} Produto`
                }
                handleClose={handleClose}
                content={
                    <>
                        <Box className='item-content-modal noborder' >

                            <Box className='item-profile-row'>
                                
                                <MegaAutoComplete
                                        value={state.productdesc}
                                        onChange={(data) => setState({ product : data, productdesc: data?.label })}
                                        noOptionsText={'Digite para procurar um produto'}
                                        timeoutUrl='/v10/consulta_produtos/'
                                        timeoutParam='label'
                                        label='Produto'
                                    />

                            </Box>

                            <Box className='item-profile-row'>
                                <TextField
                                        id="price"
                                        type="text"
                                        label="Preço"
                                        value={state.price}
                                        onChange={(e) => { setState({ 'price': parseInt(e.target.value) }) }}
                                        fullWidth={false}
                                        size='small'
                                        inputProps={{
                                            maxLength: 50
                                        }}
                                    />

                                    <TextField
                                        id="amount"
                                        type="text"
                                        label="Quantidade"
                                        value={state.amount}
                                        onChange={(e) => { setState({ 'amount': parseInt(e.target.value) }) }}
                                        fullWidth={false}
                                        size='small'
                                        inputProps={{
                                            maxLength: 50
                                        }}
                                    />
                            </Box>

                           
                    </Box>

                        
                    </>
                }
                action={
                    <>
                        <Tooltip placement="left" title={validData}>
                            <Box>
                                <ColorButton
                                    onClick={() => submit(state, reloadData, setLoader, handleClose)}
                                    disabled={validData}
                                >Salvar
                                </ColorButton>
                            </Box>
                        </Tooltip>
                    </>
                }
            />
        </>
    )
}

export default EditProducts;
import { Box } from "@mui/material"

const validateData = (data) => {

    const response = []
    if (!data?.name?.length > 0)
        response.push(<Box>Digite o nome.</Box>)

    if (!data?.value < 0)
        response.push(<Box>Digite o valor.</Box>)

    if (!data?.add_value < 0)
        response.push(<Box>Digite o Ad. Valorem.</Box>)

    if (response.length === 0)
        return ''

    return (response)
}

export default validateData
import React, { useContext, useEffect, useState } from 'react'

import ColorButton from '../Buttons/ColorButton'
import apptheme from '../theme/default'
import Users from './Users'
import { Box } from '@mui/material'
import './styles.css'

import ReplyIcon from '@mui/icons-material/Reply';
import DefButton from '../Buttons/defbutton'

import GroupIcon from '@mui/icons-material/Group';
import Person2Icon from '@mui/icons-material/Person2';
import EmailIcon from '@mui/icons-material/Email';
import Profile from './Profile'
import Email from './Email'
import { getSession } from '../../contexts/auth'
import ApartmentIcon from '@mui/icons-material/Apartment';
import Enterprise from './Enterprise'
import Teams from './Teams'
import Diversity3Icon from '@mui/icons-material/Diversity3';
import WorkspacesIcon from '@mui/icons-material/Workspaces';
import { useWindowSize } from "@uidotdev/usehooks";
import Groups from './Groups'
import RefreshIcon from '@mui/icons-material/Refresh';
import UpdateUserData from '../Utils/updateUserData'
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import Audit from './Audit'
import { externalComponent } from '../AppRoutes'

const MenuList = () => {

    const user = getSession()
    const permissions = user?.permissions

    return ({
        'users': {
            title: 'Configurar Usuários',
            icon: (<GroupIcon />),
            obj: (<Users />),
            permission: (permissions.view_user)
        },
        'profile': {
            title: 'Perfil',
            icon: (<Person2Icon />),
            obj: (<Profile />),
            permission: !user?.enterprise?.integrated
        },
        'email': {
            title: 'Email',
            icon: (<EmailIcon />),
            obj: (<Email />),
            permission: (permissions.view_emails)
        },
        'empresa': {
            title: 'Empresa',
            icon: (<ApartmentIcon />),
            obj: (<Enterprise />),
            permission: (permissions.view_enterprise)
        },
        'groups': {
            title: 'Grupos de usuário',
            icon: (<WorkspacesIcon />),
            obj: (<Groups />),
            permission: (permissions.view_groupextension)
        },
        'times': {
            title: 'Times',
            icon: (<Diversity3Icon />),
            obj: (<Teams />),
            permission: (permissions.view_team)
        },
        'audit': {
            title: 'Auditoria',
            icon: (<AssignmentTurnedInIcon />),
            obj: (<Audit />),
            permission: (Object.keys(permissions).find(each => each.includes('view_historical')))
        },
        'refresh': {
            title: 'Atualizar dados de usuário',
            icon: (<RefreshIcon />),
            obj: (<></>),
            permission: true,
            onClick: () => UpdateUserData()
        },
    })
}

export const divideArray = (array, value) => {
    const size = value
    const arrays = []
    while (array.length > 0) {
        arrays.push(array.splice(0, size));
    }
    return arrays
}

export const CalcWidthSize = (size) => {
    const threshold = size?.width < 768 ? 200 : 400
    const calcSize = Math.round(size?.width / threshold)
    if (!calcSize)
        return 2
    return calcSize
}

const Settings = () => {
    const { isMobile } = useContext(externalComponent)
    const [selectedTab, setSelectedTab] = useState()
    const size = useWindowSize();

    const menulist = MenuList()

    const calcSize = CalcWidthSize(size)

    const itens = Object.entries(menulist).filter(([key, value]) => value.permission)

    useEffect(()=>{
        window.backtobase = () => setSelectedTab()
    }, [])

    return (
        <>
            {!selectedTab ?
                <>

                    <Box className='gridBox'  sx={{ gridTemplateColumns: `repeat(${calcSize}, minMax(0, 1fr))` }}>
                        {itens.map(([key, value]) => (
                            <ColorButton
                                onClick={() => value.onClick ? value.onClick() : setSelectedTab(key)}
                                sx={{ height: '8rem', flex: 1 , visibility: !value.obj ? 'hidden' : 'visible' }}
                                fColor={apptheme.primaryColor}
                                sColor={apptheme.tertiaryColor}>
                                <Box className='flexbox'>
                                    {value.icon}
                                    {value.title}
                                </Box>
                            </ColorButton>

                        ))}
                    </Box>

                </>
                :
                <>
                    <Box className={`settings-back-button ${isMobile ? '': 'hidden'}`}>
                        <DefButton
                            onClick={() => setSelectedTab()}
                        >
                            <ReplyIcon />
                        </DefButton>
                    </Box>
                    {MenuList()[selectedTab].obj}
                </>
            }

        </>
    )
}

export default Settings
import { Box } from "@mui/material"

const validateData = (data) => {

    const response = []

    if (!data?.agent)
        response.push(<Box>Selecione um agente.</Box>)

    if (!data?.armador)
        response.push(<Box>Selecione um armador.</Box>)

    if (!data?.pol?.length > 0)
        response.push(<Box>Digite o POL.</Box>)

    if (!data?.pod?.length > 0)
        response.push(<Box>Digite o POD.</Box>)

    if (!data?.value > 0)
        response.push(<Box>Digite o valor.</Box>)

    if (!data?.coin?.length > 0)
        response.push(<Box>Digite o moeda.</Box>)

    if (!data?.charge?.length > 0)
        response.push(<Box>Selecione a cobrança.</Box>)

    if (!data?.transit_time?.length > 0)
        response.push(<Box>Digite o tempo de trânsito.</Box>)

    if (!data?.start_date)
        response.push(<Box>Digite a data inicial.</Box>)

    if (!data?.end_date)
        response.push(<Box>Digite a data final.</Box>)
    
    if (response.length === 0)
        return ''

    return (response)
}

export default validateData
import React, { useReducer, useContext, useEffect, useMemo } from 'react'
import { lockedReducer } from '../../Utils/reducers'
import { api } from '../../../services/api'
import ColorButton from '../../Buttons/ColorButton';
import DefaultModal from '../../Utils/DefaultModal';
import toast from 'react-hot-toast'
import { Box, TextField, Tooltip, FormControl, Select, MenuItem, InputLabel, IconButton } from '@mui/material'
import { validateAddData, validateDataParcels as validateData } from './validateData';
import { externalComponent } from '../../AppRoutes';
import { DataGrid, GridToolbarContainer } from '@mui/x-data-grid'
import MegaAutoComplete from '../../Utils/megautocomplete';
import { FloatOnly } from '../../Utils/IntegerOnly';
import DeleteIcon from '@mui/icons-material/Delete';
import { ResponsiveActionBox } from '../../Table/mobileTable';
import ConfirmationDialog from '../../Utils/ConfirmationDialog';

const url = '/cambio/'

const finPagamentos = ['Inv. Exportador', 'Adiantamento', 'Saldo']

const AddData = ({ parent, setParent }) => {

    const [state, setState] = useReducer(lockedReducer, {
        import: '',
        finpag: 'Inv. Exportador',
        value: ''
    })

    const validate = validateAddData(state)

    return (
        <DefaultModal
            dialogProps={{
                maxWidth: 'xs'
            }}
            title='Adicionar Parcela'
            handleClose={() => setParent({ addParcel: null })}
            content={
                <Box className='item-content-modal noborder' >
                    <MegaAutoComplete
                        value={state.import}
                        onChange={(e) => setState({ import: e })}
                        noOptionsText={'Digite para procurar Importações'}
                        timeoutUrl='/finimp/importacao/'
                        timeoutParam='name'
                        label={'Importação'}
                    />
                    <FormControl sx={{ minWidth: 120 }} size='small' >
                        <InputLabel id='select-parcel-finpag'>Fin. Pagamento</InputLabel>
                        <Select
                            labelId='select-parcel-finpag'
                            id="select-finpag"
                            label='Fin. Pagamento'
                            value={state.finpag}
                            onChange={(e) => {
                                setState({ finpag: e.target.value })
                            }}
                        >
                            {finPagamentos.map((each) =>
                                <MenuItem value={each}>{each}</MenuItem>
                            )}

                        </Select>
                    </FormControl>
                    <TextField
                        id={`valor-decideradd-0`}
                        type="text"
                        value={state.value}
                        onChange={(e) => {
                            setState({ value: FloatOnly(e.target.value) })
                        }}
                        fullWidth={false}
                        size='small'
                        label={'Valor'}
                    />
                </Box>
            }
            action={
                <Tooltip title={validate}>
                    <span>
                        <ColorButton
                            disabled={validate}
                            onClick={() => {
                                const newrow = {
                                    OID: Math.random(),
                                    OIDImportacao: state.import.id,
                                    Ref: state.import.label,
                                    FinalidadePag: state.finpag,
                                    ValorPorImportacao: state.value,
                                    added: true
                                }
                                setParent({
                                    rows: [...parent.rows, newrow],
                                    changes: { ...(parent.changes || {}), [newrow.OID]: { ...newrow } },
                                    addParcel: null
                                })
                            }}
                        >
                            Adicionar
                        </ColorButton>
                    </span>
                </Tooltip>

            }
        />
    )
}

const CustomToolbar = (setState) => {

    return (
        <GridToolbarContainer>
            <ColorButton reverse={true} onClick={() => setState({ addParcel: {} })}>Adicionar</ColorButton>
        </GridToolbarContainer>
    )
}

const columns = (rows, setState) => {
    return [
        {
            field: 'Ref', headerName: 'Importação', flex: 1, filterType: 'string', editable: true,
            renderEditCell: (params) => (
                <MegaAutoComplete
                    value={params.value}
                    onChange={(data) => {
                        if (data) {
                            params.api.state.rows.dataRowIdToModelLookup[params.id].OIDImportacao = data.id
                            params.api.setEditCellValue({ id: params.id, field: params.field, value: data.label })
                        }
                    }
                    }
                    noOptionsText={'Digite para procurar Importações'}
                    timeoutUrl='/cambio/importacao/'
                    timeoutParam='name'
                />
            )
        },
        {
            field: 'FinalidadePag', headerName: 'Finalidade Pag', flex: 1, filterType: 'string', editable: true,
            renderEditCell: (params) => (
                <FormControl sx={{ minWidth: 120 }} size='small' >
                    <Select
                        id="select-finpag"
                        value={params.value}
                        onChange={(e) => {
                            params.api.setEditCellValue({ id: params.id, field: params.field, value: e.target.value })
                        }}
                    >
                        {finPagamentos.map((each) =>
                            <MenuItem value={each}>{each}</MenuItem>
                        )}

                    </Select>
                </FormControl>
            )
        },
        {
            field: 'ValorPorImportacao', headerName: 'Valor', flex: 1, filterType: 'number', editable: true,
            renderEditCell: (params) => (
                <TextField
                    id={`valor-decider-${params.id}`}
                    type="text"
                    value={params.value}
                    onChange={(e) => {
                        params.api.setEditCellValue({ id: params.id, field: params.field, value: Number(e.target.value) })
                    }}
                    fullWidth={false}
                    size='small'
                />
            ),
        },
        {
            field: 'actions', headerName: '', width: 50, filterable: false,
            renderCell: (props) => {
                return (
                    <ResponsiveActionBox >
                        {<Tooltip title='Excluir parcela'><IconButton onClick={() => { setState({ deleteRow: props.row }) }}><DeleteIcon /></IconButton></Tooltip>}
                    </ResponsiveActionBox>
                )
            }
        },
    ]
}

const submit = async (data, setState, setLoader) => {

    setLoader(true)
    let res = null

    if (data.OID)
        res = await api.post(`${url}${data.OID}/commit/`, data.changes)
    if (res.status === 201 || res.status === 200) {
        toast.success(`Parcelas ${res.status === 201 ? 'adicionadas' : 'editadas'} com sucesso`)
        getData(data, setState, setLoader)
        setState({confirmAdd: null})
        setLoader(false)
        return
    }
    toast.error(`Parcelas ${!data.OID ? 'adicionadas' : 'editadas.'} Motivo: ${res.data}`)
    setLoader(false)
}

const getTitle = (data) => {
    if (data?.OID)
        return 'Editar'
    return 'Adicionar'
}

const getData = async (data, setState, setLoader) => {
    setLoader(true)
    const res = await api.get(`${url}${data.OID}/parcels/`)
    if (res.status === 200) {
        setState({ rows: res.data, ready: true, changes:null })
        setLoader(false)
        return
    }
    setLoader(false)
    toast.error(`Parcelas não localizadas Motivo: ${res.data}`)
}

const EditParcelsModal = ({ data, handleClose, reloadData }) => {

    const { setLoader } = useContext(externalComponent)

    const [state, setState] = useReducer(lockedReducer, {
        rows: [],
        ready: false,
        ...data,
        OID: data.OID ? data.OID : 0,
        changes: null,
        addParcel: null,
        deleteRow: null,
        confirmAdd: null
    })

    const validData = validateData(state)

    useEffect(() => {
        getData(data, setState, setLoader)
    }, [data, setLoader])

    const rendered_columns = useMemo(() => columns(state.rows, setState), [state.rows])

    return (
        <>
            <DefaultModal
                title={
                    `${getTitle(state)} Parcelas do contrato ${data.OID}`
                }
                handleClose={handleClose}
                content={
                    <>
                        {state.ready && <Box className='item-content-modal noborder' >
                            <DataGrid
                                className='mixed-launch'
                                rows={state.rows}
                                columns={rendered_columns}
                                hideFooter={true}
                                density='compact'
                                slots={{
                                    toolbar: () => CustomToolbar(setState)
                                }}
                                processRowUpdate={(updatedRow) => {
                                    const row = state.rows.find(each => each.OID === updatedRow.OID)
                                    if (row) {
                                        Object.assign(row, updatedRow)
                                        setState({ rows: [...state.rows], changes: { ...(state.changes || {}), [updatedRow.OID]: { ...updatedRow, changed: true } } })
                                    }
                                    return updatedRow
                                }}
                                getRowId={(e) => e.OIDImportacao}
                            />
                            <Box sx={{ display: 'flex', gap: '.5rem' }}>
                                <span>Valor total:</span>
                                <span>{state.rows.map(e => Number(e.ValorPorImportacao)).reduce((x, y) => x + y, 0)}</span>
                            </Box>
                        </Box>}
                    </>
                }
                action={
                    <>
                        <Tooltip placement="left" title={validData}>
                            <Box>
                                <ColorButton
                                    onClick={() => setState({confirmAdd: state.changes})}
                                    disabled={validData}
                                >Salvar
                                </ColorButton>
                            </Box>
                        </Tooltip>
                    </>
                }
            />
            {state.addParcel && <AddData parent={state} setParent={setState} />}
            {state.deleteRow &&
                <ConfirmationDialog
                    content={`Deseja excluir a parcela? ${state.deleteRow.Ref} - ${state.deleteRow.FinalidadePag} - ${state.deleteRow.ValorPorImportacao}?`}
                    handleClose={() => setState({ deleteRow: null })}
                    onConfirm={() => setState({
                        rows: [...state.rows.filter(each => each.OID !== state.deleteRow.OID)],
                        changes: { ...(state.changes || {}), [state.deleteRow.OID]: { ...state.deleteRow, deleted: true } },
                        deleteRow: null
                    })}
                />
            }
            {state.confirmAdd &&
                <ConfirmationDialog
                    content={`Deseja salvar as alterações?`}
                    handleClose={() => setState({ confirmAdd: null })}
                    onConfirm={() => submit(state, setState, setLoader)}
                />
            }
        </>
    )
}

export default EditParcelsModal;
import React, { useContext, useMemo, useReducer, useEffect } from 'react'
import { Box, IconButton, Tooltip } from '@mui/material'
import { api } from '../../../services/api';
import toast from 'react-hot-toast'
import { getSession } from '../../../contexts/auth';
import { lockedReducer } from '../../Utils/reducers';
import TableRowsIcon from '@mui/icons-material/TableRows';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ConfirmationDialog from '../../Utils/ConfirmationDialog';
import { externalComponent } from '../../AppRoutes';
import Table, { TableHook } from '../../Table';
import EditShippingAgentModal from './edit';
import { ResponsiveActionBox } from '../../Table/mobileTable';
import EditParcelsModal from './parcels';

const url = '/cambio/'

const submitDelete = async (data, setState, getData, setLoader) => {
    setLoader(true)
    const res = await api.delete(`${url}${data.id}/`)
    setLoader(false)
    if (res.status === 204) {
        toast.success('Deletado com sucesso')
        setState({ deleteRow: false })
        getData()
        return
    }
    console.error('Falha ao deletar')

}

const columns = (setState, permissions) => {
    return [
        {
            field: 'actions', headerName: 'Ações', filterable: false,
            renderCell: (props) => {
                return (
                    <ResponsiveActionBox >
                        {permissions.change_legacy && <Tooltip title='Parcelas'><IconButton onClick={() => { setState({ parcels: props.row }) }}><TableRowsIcon /></IconButton></Tooltip>}
                    </ResponsiveActionBox>
                )
            }
        },
        { field: 'NrContPrincCbo', headerName: 'CONTRATO CBO', width: 150, filterType: 'string' },
        { field: 'NrContJrsCbo', headerName: 'CONTRATO JRS', width: 150, filterType: 'string' },
        { field: 'TipoPgto', headerName: 'TIPO PGTO', width: 150, filterType: 'string' },
        { field: 'ModalidadeCbo', headerName: 'MODALIDADE CBO', width: 150, filterType: 'string' },
        { field: 'VlrPrincCbo', headerName: 'VLR CÂMBIO', width: 150, filterType: 'number' },
        { field: 'VlrJrsCbo', headerName: 'VLR JUROS', width: 150, filterType: 'number' },
        { field: 'TxDlrCbo', headerName: 'TX DOLAR', width: 150, filterType: 'number' },
        { field: 'DtFchCbo', headerName: 'DT FECH', width: 150, filterType: 'daterangeless' },
        { field: 'DtLiqCbo', headerName: 'DT LIQ', width: 150, filterType: 'daterangeless' },
        { field: 'DespFchCbo', headerName: 'DESP FECH', width: 150, filterType: 'string' },
        { field: 'BcoCbo', headerName: 'BANCO', width: 150, filterType: 'string' },
        { field: 'NrInstCbo', headerName: 'INSTITUIÇÃO', width: 150, filterType: 'string' },
        { field: 'PcaBcoCbo', headerName: 'PRAÇA', width: 150, filterType: 'string' },
        { field: 'asd', headerName: 'CONTRATO FIN', width: 150, filterType: 'string' },
        { field: 'idendsatifier', headerName: 'PARCELA', width: 150, filterType: 'string' },
        { field: 'ds', headerName: 'OBSERVAÇÕES', width: 150, filterType: 'string' },

    ]
}

const Cambio = () => {

    const { setLoader } = useContext(externalComponent)

    const user = getSession()

    const [state, setState] = useReducer(lockedReducer, {
        rows: [],
        parcels: null,
        editRow: null,
        deleteRow: null,
    })

    const [table, reloadData] = TableHook()


    return (
        <>
            {state.deleteRow &&
                <ConfirmationDialog
                    content={`Deseja excluir as parcelas ${state.deleteRow.name} - ${state.deleteRow.identifier}?`}
                    handleClose={() => setState({ deleteRow: null })}
                    onConfirm={() => submitDelete(state.deleteRow, setState, reloadData, setLoader)}
                />
            }
            {state.editRow &&
                <EditShippingAgentModal
                    data={state.editRow}
                    handleClose={() => setState({ editRow: null })}
                    reloadData={reloadData}
                />
            }
            {state.parcels &&
                <EditParcelsModal
                    data={state.parcels}
                    handleClose={() => setState({ parcels: null })}
                    reloadData={reloadData}
                />
            }

            <Box className='information-table'>
                <Box className='user-table'>
                    <Table
                        rows={state.rows}
                        columns={useMemo(() => columns(setState, user.permissions), [user.permissions])}
                        pagination
                        density='compact'
                        slots={{
                            returnComponent: ()=> window.backtobase(),
                            //   addComponent: () => user.permissions.add_shippingagent ? setState({ editRow: {} }) : null,
                            filterComponent: true,
                            columnComponent: true
                        }}
                        toolbar
                        getURL={url}
                        updateRows={(rows) => setState({ 'rows': rows })}
                        innerRef={table}
                        getRowId={e => e.OID}
                    />
                </Box>
            </Box>
        </>
    )
}

export default Cambio;
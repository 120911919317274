import { Box } from "@mui/material"

const validateData = (data) => {

    const response = []

    if (!data?.codpro?.length > 0)
        response.push(<Box>Digite o codpro.</Box>)

    if (!data?.description?.length > 0)
        response.push(<Box>Digite a descrição.</Box>)

    if (!data?.highlight_ncm?.length > 0)
        response.push(<Box>Digite o destaque NCM.</Box>)

    if (!data?.nve?.length > 0)
        response.push(<Box>Digite o NVE.</Box>)

    if (!data?.inmetro_certificate?.length > 0)
        response.push(<Box>Digite o Certif. Inmetro.</Box>)

    if (!data?.reg_orquestra?.length > 0)
        response.push(<Box>Digite o Reg. Orquestra.</Box>)

    if (!data?.family?.length > 0)
        response.push(<Box>Digite a Família.</Box>)

    if (!data?.type?.length > 0)
        response.push(<Box>Digite o tipo.</Box>)

    if (!data?.unity?.length > 0)
        response.push(<Box>Digite a unidade.</Box>)

    if (!data?.tax_regime?.length > 0)
        response.push(<Box>Digite o regime de tributação.</Box>)

    if (!data?.specification?.length > 0)
        response.push(<Box>Digite a especificação.</Box>)

    if (!data?.legal_substantiation?.length > 0)
        response.push(<Box>Digite a fundamentação Legal.</Box>)

    if (response.length === 0)
        return ''

    return (response)
}

export default validateData

import { useState, useEffect, useContext } from 'react';
import './menu.css'
import apptheme from '../theme/default';

//import CottageIcon from '@mui/icons-material/Cottage';
//import SettingsIcon from '@mui/icons-material/Settings';
import { useNavigate } from "react-router-dom";
import LogoutIcon from '@mui/icons-material/Logout';
import { Box, Button } from '@mui/material';
import Logout from '../Settings/Logout'
import { useLocation } from 'react-router-dom'
import MenuIcon from '@mui/icons-material/Menu';
import GroupsIcon from '@mui/icons-material/Groups';
import DashboardCustomizeIcon from '@mui/icons-material/DashboardCustomize';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import { externalComponent } from '../AppRoutes';
import SettingsIcon from '@mui/icons-material/Settings';
import { getSession } from '../../contexts/auth';
import ElderlyIcon from '@mui/icons-material/Elderly';
import ReceiptIcon from '@mui/icons-material/Receipt';
import Submenu from './submenu-item';


const menus = () => {
  const user = getSession()
  const permissions = user?.permissions

  return ({
  pedidos: { title: 'Pedidos', icon: (<ReceiptIcon />),permissions: true},
  cockpit: { title: 'Painel de controle', icon: (<RocketLaunchIcon />), permissions: permissions?.view_schedule | permissions?.view_proposal | permissions?.view_serviceorder },
  cadastros: { title: 'Cadastros Gerais', icon: (<DashboardCustomizeIcon />), permissions: permissions?.view_product  },                                                           
  legacy: { title: 'Legado', icon: (<ElderlyIcon />), permissions: permissions.view_legacy },
  settings: { title: 'Configurações', icon: (<SettingsIcon />), permissions: true},

})

}

const exit = () => ({
  exit: { title: 'Sair', icon: (<LogoutIcon />), overthrow: { onClick: Logout }, permissions: true }
})

const MenuComponent = () => {

  const user = getSession()
  const logo = user.enterprise.logo

  const {isMobile} = useContext(externalComponent)
  const [showMenu, setShowmenu] = useState(false)

  const selectedTheme = {
    sx: {
      color: apptheme.secondaryColor,
      backgroundColor: `${apptheme.tertiaryColor} !important`
    },
    className: 'main-menu-button selected'
  }

  const menuTheme = {
    sx: { color: apptheme.secondaryColor },
    className: 'main-menu-button'
  }

  const decideTheme = (actualRoute) => {
    if (actualRoute.replaceAll('/', '') === route.pathname.replaceAll('/', ''))
      return { ...selectedTheme }
    return { ...menuTheme }
  }

  const decideThemeSubmenu = (routeList) => {
    const selected = routeList.includes(route.pathname) 
    if (selected)
      return { ...selectedTheme }
    return { ...menuTheme }
  }

  const route = useLocation()
  const navigate = useNavigate();

  useEffect(()=>{
    if (!('backtobase' in window))
      window.backtobase = () => {}
  }, [])

  return (
    <Box className={`${(isMobile || showMenu) ? 'overlay' : ''}`} sx={{height: (!isMobile || showMenu) ? '100%' : 'max-content'}}>
    {(!isMobile || showMenu) ? 
    <Box className='lateral-menu' sx={{ backgroundColor: apptheme.primaryColor, height: "100%" }}>
      <Box className='inside-menu'>
        <Button
        onClick={() => navigate(`/cadastros`)}
        children={<img src={logo ? process.env.REACT_APP_URL_PLATESYS.replace('/api/', '')+logo : '/static/trophy.png'} alt='DetSys' />}
        >
        </Button>
        <Box className='userText'>Usuário: {user.first_name} {user.last_name}</Box>
        {Object.entries(menus()).map(([key, value]) =>
          <>
            {value.permissions ?
            <>
              {!value.submenu ? <Button
                {...decideTheme(`/${key}`)}
                onClick={() => { window?.backtobase(); navigate(`/${key}`); setShowmenu(false)}}
                children={<>{value.icon}<span>{value.title}</span></>}
                {...value.overthrow}
              >
              </Button> 
               :
                <Submenu config={value} theme={decideThemeSubmenu} />
              }
            </>
              : null}
          </>
        )}
      </Box>
      <Box className='inside-menu' >
        {Object.entries(exit()).map(([key, value]) =>
          <>
            {value.permissions ?
              <Button
                {...decideTheme(`/${key}`)}
                
                onClick={() => navigate(`/${key}`)}
                children={<>{value.icon}<span>{value.title}</span></>}
                {...value.overthrow}
              >
              </Button> : null}
          </>
        )}
      </Box> 
    </Box>
    :
    <Box className='inside-menu'>
      <Button
        sx={{
          color: apptheme.tertiaryColor,
          placeContent: 'flex-start'
        }} 
        onClick={()=>{setShowmenu(true)}}
        children={<MenuIcon />}
        className='main-menu-button hamburguer'
        >
       </Button>
    </Box>
    }
    {showMenu && <Box onClick={()=>setShowmenu(false)} className='backdrop'></Box>} 
    </Box>
  )
};

export default MenuComponent;
import React, { useReducer, useContext, useEffect, useRef } from 'react'
import { lockedReducer } from '../../Utils/reducers'
import { externalComponent } from '../../AppRoutes'
import { Box, TextField, Autocomplete } from '@mui/material'
import timeoutList from '../../Utils/TimeoutList'
import { CircularProgress } from '@mui/material'
import ColorButton from '../../Buttons/ColorButton'
import SaveIcon from '@mui/icons-material/Save';
import { api } from '../../../services/api'
import { getSession } from '../../../contexts/auth'
import dayjs from 'dayjs'
import { DateTimeField } from '@mui/x-date-pickers'
import { DatePicker } from '@mui/x-date-pickers'
import './styles.css'
import { PhoneMask } from '../../Utils/MaskedInput'
import toast from 'react-hot-toast'
import uploadfilefunction from '../../Utils/uploadfilefunction'
import { handleImageChange } from '../../Utils/imageChange'
import { CepMask, CnpfMask } from '../../Utils/MaskedInput'
import cepLoad from '../../Utils/Cepload'
import { NumbersOnly } from '../../Utils/IntegerOnly'
import ReplyIcon from '@mui/icons-material/Reply'

const saveData = async (state, setState, setLoader) => {
    const user = getSession()
    setLoader(true)
    uploadfilefunction(state.upload_file, `/enterprise/${user.enterprise.id}/uploadlogo/`)
    const { upload_url, upload_file, ...data } = state
    const res = await api.patch(`/enterprise/${user.enterprise.id}/`, data)
    if (res.status === 200) {
        res.data.enterprisesettings.responsable = res.data.enterprisesettings.responsableserialized
        setState(res.data)
        toast.success('Dados de empresa atualizados com sucesso!')
    }

    setLoader(false)
    if (res.status !== 200)
        toast.error(res.error)
}

const loadData = async (setState, setLoader) => {
    const user = getSession()
    setLoader(true)
    const res = await api.get(`/enterprise/${user.enterprise.id}/`)
    if (res.status === 200) {
        res.data.enterprisesettings.responsable = res.data.enterprisesettings.responsableserialized
        setState(res.data)
    }
    setLoader(false)
}

const handleCepComplete = async (e, setState, state) => {
    const cep = NumbersOnly(e.target.value);
    setState({ ...state, cep: cep });
    if (e.type === 'change' && cep.length === 8) {
        const addressData = await cepLoad(cep);
        if (addressData.data.erro === true) {
            setState({
                ...state,
                street: "",
                neighborhood: "",
                city: "",
                uf: ""
            });
        } else {
            setState({
                ...state,
                street: addressData.data.logradouro,
                neighborhood: addressData.data.bairro,
                city: addressData.data.localidade,
                uf: addressData.data.uf
            });
        }
    }
}



const Enterprise = () => {

    const permissions = getSession().permissions
    const { setLoader, isMobile } = useContext(externalComponent)

    const fileRef = useRef()

    const [state, setState] = useReducer(lockedReducer, {
        "id": 0,
        "name": "",
        "subscription": "",
        "phone_number": "",
        "logo": "",
        "upload_url": null,
        "upload_file": null,
        "created_at": null,
        "licencas": 0,
        "cnpj": "",
        "street_number": "",
        "street": "",
        "city": "",
        "uf": "",
        "neighborhood": "",
        "cep": "",
        "complex": "",
        "email": "",
        "site": "",
        "enterprisesettings": {
            "responsable": '',
            "alvara": '',
            "val_alvara": '',
            "licenca_ambiental": '',
            "licenca_prefeitura": '',
            "val_licenca": '',
            "val_pref_licenca": '',
            "IM": '',
            "IEMA": '',
            "IE": '',
            "CREA": '',
        }
    })

    const [helpers, setHelpers] = useReducer(lockedReducer, {
        loaded_users: []
    })

    useEffect(() => {
        loadData(setState, setLoader)
    }, [setLoader])

    return (
        <>
            <Box sx={{ display: 'flex', gap: '1rem' }}>
                {!isMobile && <ColorButton
                    className='profile-save-button'
                    onClick={() => window.backtobase()}
                >
                    <Box className='flexbox'><ReplyIcon /> Voltar</Box>
                </ColorButton>}
                <ColorButton
                    className='profile-save-button'
                    disabled={!permissions.change_enterprise}
                    onClick={() => saveData(state, setState, setLoader)}
                >
                    <Box className='flexbox'><SaveIcon /> Salvar</Box>
                </ColorButton>
            </Box>
            <Box className='profile-overflow'>
                <Box className='item-content-modal item-padding' >
                    <h3>Config. Empresa</h3>
                    <Box className='item-profile-row'>
                        <TextField
                            id="clientname"
                            type="text"
                            size='small'
                            sx={{ backgroundColor: 'white' }}
                            label="Nome"
                            value={state.name}
                            onChange={(e) => { setState({ 'name': e.target.value }) }}
                            inputProps={{
                                maxLength: 50
                            }}
                        />
                        <TextField
                            id="clientlastname"
                            type="text"
                            sx={{ backgroundColor: 'white' }}
                            label="Assinatura"
                            disabled
                            size='small'
                            defaultValue={state.subscription}
                            inputProps={{
                                maxLength: 50
                            }}
                        />

                        <DateTimeField
                            size='small'
                            label='Data de criação'
                            value={dayjs(state.created_at)}
                            disabled
                            format='DD/MM/YYYY HH:mm'
                            sx={{ maxWidth: '10rem' }}
                        />
                        <TextField
                            id="clientname"
                            type="text"
                            size='small'
                            sx={{ backgroundColor: 'white', maxWidth: '10rem' }}
                            label="Número de licenças"
                            value={state.licencas}
                            disabled
                            onChange={(e) => { setState({ 'licencas': e.target.value }) }}
                            inputProps={{
                                maxLength: 50
                            }}
                        />
                    </Box>
                    <Box className='item-profile-row'>
                        <Box className='enterprise-logobox'>
                            <Box className='enterprise-logo-label'>Logo da empresa</Box>
                            <Box onClick={() => { fileRef.current.click() }} className='enterprise-logo-overlay'>Alterar</Box>
                            <Box className='enterprise-img-container'>
                                <img src={state.upload_url ? state.upload_url : state.logo} alt={state.name}></img>
                            </Box>
                            <input
                                ref={fileRef}
                                style={{ 'display': 'none' }}
                                type='file'
                                onChange={(e) => handleImageChange(e, setState)}
                                accept="image/png, image/jpeg">
                            </input>
                        </Box>
                        <Box className='item-enterprise-row'>
                            <TextField
                                id="cnpj"
                                type="text"
                                size='small'
                                sx={{ backgroundColor: 'white' }}
                                label="CNPJ"
                                value={state.cnpj}
                                onChange={(e) => { setState({ 'cnpj': e.target.value }) }}
                                InputProps={{
                                    inputComponent: CnpfMask
                                }}
                            />
                            <TextField
                                id="cep"
                                type="text"
                                size='small'
                                sx={{ backgroundColor: 'white' }}
                                label="CEP"
                                value={state.cep}
                                onChange={(e) => handleCepComplete(e, setState, state)}
                                InputProps={{
                                    inputComponent: CepMask
                                }}
                            />
                            <TextField
                                id="street"
                                type="text"
                                size='small'
                                sx={{ backgroundColor: 'white' }}
                                label="Rua"
                                value={state.street}
                                onChange={(e) => { setState({ 'street': e.target.value }) }}
                                inputProps={{
                                    maxLength: 100
                                }}
                            />

                            <Box sx={{ display: 'flex', gap: 1 }}>
                                <TextField
                                    sx={{ backgroundColor: 'white', flex: 1 }}
                                    id="city"
                                    type="text"
                                    size='small'
                                    label="Cidade"
                                    value={state.city}
                                    onChange={(e) => { setState({ 'city': e.target.value }) }}
                                    inputProps={{
                                        maxLength: 50
                                    }}
                                />
                                <TextField
                                    sx={{ flex: .5 }}
                                    id="uf"
                                    type="text"
                                    label="UF"
                                    size='small'
                                    value={state.uf}
                                    onChange={(e) => { setState({ 'uf': e.target.value }) }}
                                    fullWidth={false}
                                    inputProps={{
                                        maxLength: 100
                                    }}
                                />
                            </Box>


                            <TextField
                                id="neighborhood"
                                type="text"
                                size='small'
                                sx={{ backgroundColor: 'white' }}
                                label="Bairro"
                                value={state.neighborhood}
                                onChange={(e) => { setState({ 'neighborhood': e.target.value }) }}
                                inputProps={{
                                    maxLength: 50
                                }}
                            />

                            <Box sx={{ display: 'flex', gap: 1 }}>
                                <TextField
                                    sx={{ backgroundColor: 'white', flex: 1 }}
                                    id="complex"
                                    type="text"
                                    size='small'
                                    label="Complemento"
                                    value={state.complex}
                                    onChange={(e) => { setState({ 'complex': e.target.value }) }}
                                    inputProps={{
                                        maxLength: 100
                                    }}
                                />

                                <TextField
                                    sx={{ backgroundColor: 'white', flex: '.3' }}
                                    id="street_number"
                                    type="text"
                                    size='small'
                                    label="Número"
                                    value={state.street_number}
                                    onChange={(e) => { setState({ 'street_number': e.target.value }) }}
                                    inputProps={{
                                        maxLength: 100
                                    }}
                                />
                            </Box>

                            <TextField
                                id="phone_number"
                                type="text"
                                label="Telefone"
                                value={state.phone_number}
                                onChange={(e) => { setState({ 'phone_number': NumbersOnly(e.target.value) }) }}
                                fullWidth={false}
                                size='small'
                                InputProps={{
                                    inputComponent: PhoneMask
                                }}
                            />

                            <TextField
                                id="email"
                                type="text"
                                size='small'
                                sx={{ backgroundColor: 'white' }}
                                label="E-mail"
                                value={state.email}
                                onChange={(e) => { setState({ 'email': e.target.value }) }}
                                inputProps={{
                                    maxLength: 100
                                }}
                            />


                            <TextField
                                id="site"
                                type="text"
                                size='small'
                                sx={{ backgroundColor: 'white' }}
                                label="Site"
                                value={state.site}
                                onChange={(e) => { setState({ 'site': e.target.value }) }}
                                inputProps={{
                                    maxLength: 100
                                }}
                            />
                        </Box>
                    </Box>

                </Box>
            </Box >
        </>
    )
}

export default Enterprise

import React, { useContext, useMemo, useReducer, useEffect } from 'react'
import { Box, IconButton } from '@mui/material'
import { api } from '../../../services/api';
import toast from 'react-hot-toast'
import { getSession } from '../../../contexts/auth';
import { lockedReducer } from '../../Utils/reducers';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ConfirmationDialog from '../../Utils/ConfirmationDialog';
import { externalComponent } from '../../AppRoutes';
import Table, { TableHook } from '../../Table';
import EditCustomsServices from './edit';

const url = '/servicos_alfandegarios/'

const submitDelete = async (data, setState, getData, setLoader) => {
    setLoader(true)
    const res = await api.delete(`${url}${data.id}/`)
    setLoader(false)
    if (res.status === 204) {
        toast.success('Deletado com sucesso')
        setState({ deleteRow: false })
        getData()
        return
    }
    console.error('Falha ao deletar')

}

export const loadData = async (setState, permission, setLoader) => {
    if (!permission) {
        toast.error('Você não tem permissão para ver os Serviços Alfandegários')
        return
    }
}

const columns = (setState, permissions) => {
    return [
        { field: 'identifier', headerName: 'Identificador', flex: 1, filterType: 'number' },
        { field: 'name', headerName: 'Nome', flex: 1, filterType: 'string' },
        {
            field: 'actions', headerName: 'Ações', filterable: false,
            renderCell: (props) => {
                return (
                    <Box >
                        {permissions.change_customsservices && <IconButton onClick={() => { setState({ editRow: props.row }) }}><EditIcon /></IconButton>}
                        {permissions.delete_customsservices && <IconButton onClick={() => { setState({ deleteRow: props.row }) }}><DeleteIcon /></IconButton>}
                    </Box>
                )
            }
        }
    ]
}

const CustomsServices = () => {

    const { setLoader } = useContext(externalComponent)

    const user = getSession()

    const [state, setState] = useReducer(lockedReducer, {
        rows: [],
        editRow: null,
        deleteRow: null,
        unitys: []
    })

    useEffect(() => {
        loadData(setState, user.permissions.view_customsservices, setLoader)
    }, [setLoader, user.permissions.view_customsservices])

    const [table, reloadTable] = TableHook()

    const reloadData = () => {
        loadData(setState, user.permissions.view_customsservices, setLoader)
        reloadTable()
    }

    return(
        <>
         {state.deleteRow &&
                <ConfirmationDialog
                    content={`Deseja excluir o serviço alfandegário ${state.deleteRow.name} - ${state.deleteRow.identifier}?`}
                    handleClose={() => setState({ deleteRow: null })}
                    onConfirm={() => submitDelete(state.deleteRow, setState, reloadData, setLoader)}
                />
         }
        {state.editRow &&
                <EditCustomsServices
                    data={state.editRow}
                    handleClose={() => setState({ editRow: null })}
                    reloadData={reloadData}
                />
        }

         <Box className='information-table'>
                <Box className='user-table'>
                    <Table
                        rows={state.rows}
                        columns={useMemo(()=> columns(setState, user.permissions), [user.permissions])}
                        pagination
                        density='compact'
                        slots={{
                            returnComponent: ()=> window.backtobase(),
                            addComponent: () => user.permissions.add_customsservices ? setState({ editRow: {} }) : null,
                            filterComponent: true,
                            columnComponent: true
                        }}
                        toolbar
                        getURL={url}
                        updateRows={(rows) => setState({'rows': rows})}
                        innerRef={table}
                    />
                </Box>
            </Box>
        </>
    )
}

export default CustomsServices;
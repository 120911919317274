import { Box } from "@mui/material"

const validateData = (data) => {

    const response = []

    if (!data?.name?.length > 0)
        response.push(<Box>Digite o nome.</Box>)

    if (!data?.phone_number?.length > 0)
        response.push(<Box>Digite o telefone.</Box>)

    if (!data?.email?.length > 0)
        response.push(<Box>Digite o email.</Box>)

    // if (!data?.brands)
    //     response.push(<Box>Selecione a marca.</Box>)

    if (!data?.cep?.length > 0)
        response.push(<Box>Digite o cep.</Box>)

    if (!data?.country)
        response.push(<Box>Selecione um país.</Box>)

    if (!data?.street?.length > 0)
        response.push(<Box>Digite a rua.</Box>)

    if (!data?.street_number?.length > 0)
        response.push(<Box>Digite o número.</Box>)

    if (!data?.city?.length > 0)
        response.push(<Box>Digite a cidade.</Box>)

    if (!data?.state?.length > 0)
        response.push(<Box>Digite o estado.</Box>)

    if (response.length === 0)
        return ''

    return (response)
}

export default validateData
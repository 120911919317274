import React, { useReducer, useContext, useEffect } from 'react'
import { lockedReducer } from '../../Utils/reducers'
import { api } from '../../../services/api'
import ColorButton from '../../Buttons/ColorButton';
import DefaultModal from '../../Utils/DefaultModal';
import toast from 'react-hot-toast'
import { Box, Tooltip, TextField } from '@mui/material'
import validateData from './validateData';
import { externalComponent } from '../../AppRoutes';
import MegaAutoComplete from '../../Utils/megautocomplete';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { number2dayweek } from '../../Utils/dayjsptbrhelper';


const url = '/painel_pedido/'

const submit = async (data, getData, setLoader, handleClose) => {

    setLoader(true)
    let res = null

    if (data.id)
        res = await api.patch(`${url}${data.id}/`, data)
    else
        res = await api.post(url, data)
    if (res.status === 201 || res.status === 200) {
        toast.success(`Pedido ${res.status === 201 ? 'adicionado' : 'editado'} com sucesso`)
        getData()
        handleClose()
        setLoader(false)
        return
    }
    toast.error(`Pedido não pôde ser ${!data.id ? 'adicionado' : 'editado.'} Motivo: ${res.data}`)
    setLoader(false)
}

const loadRetrieve = async (id, setState, setLoader) => {
    setLoader(true)
    const res = await api.get(url + `${id}/`)
    if (res.status === 200) {
        setState({ ...res.data})
    }
    setLoader(false)
}

const getTitle = (data) => {
    if (data?.id)

        return 'Editar'
    return 'Adicionar'
}

const EditFrete = ( {data, handleClose, reloadData} ) => {

    const { setLoader } = useContext(externalComponent)

    const [state, setState] = useReducer(lockedReducer, {
        exporter:'',
        order_date: '',
        observation:'',
        id: data.id ? data.id : 0
    })

    const validData = validateData(state)

    useEffect(() => {
        if (data.id)
            loadRetrieve(data.id, setState, setLoader)
        else
            setState({ loaded: true })
    }, [setLoader, data.id])
    
    return(
        <>
        <DefaultModal
                title={
                    `${getTitle(state)} Pedido`
                }
                handleClose={handleClose}
                content={
                    <>
                        <Box className='item-content-modal noborder' >

                            <Box className='item-profile-row' sx={{alignItems:'flex-end'}}>

                                <MegaAutoComplete
                                        value={state.exporter}
                                        onChange={(data) => setState({ exporter : data })}
                                        noOptionsText={'Digite para procurar um Exportador'}
                                        timeoutUrl='/exportador/exportadores/'
                                        timeoutParam='label'
                                        label='Exportador'
                                />

                                <DemoContainer components={['DatePicker']}>
                                    <DatePicker 
                                    slotProps={{ textField: { size: 'small' } }} 
                                    label="Data pedido:"
                                    size="small"
                                    value={dayjs(state.order_date)}  
                                    onChange={(e) => { setState({ 'order_date': e.format('YYYY-MM-DD') }) }}  
                                    format='MM/YYYY'
                                    dayOfWeekFormatter={(n,date) => number2dayweek[date.$W]}
                                    ampm={false}
                                    />
                                </DemoContainer>


                            </Box>

                            <Box className='item-profile-row'>

                                <TextField
                                    multiline
                                    maxRows={4}
                                    size='small'
                                    label={`Observação`}
                                    className='simple-text'
                                    value={state.observation}
                                    onChange={(e) => { setState({ observation: e.target.value }) }}
                                    inputProps={{
                                        maxLength: 5000
                                    }}
                                />

                            </Box>

                    </Box>

                        
                    </>
                }
                action={
                    <>
                        <Tooltip placement="left" title={validData}>
                            <Box>
                                <ColorButton
                                    onClick={() => submit(state, reloadData, setLoader, handleClose)}
                                    disabled={validData}
                                >Salvar
                                </ColorButton>
                            </Box>
                        </Tooltip>
                    </>
                }
            />
        </>
    )
}

export default EditFrete;
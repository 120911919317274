import { Box } from "@mui/material"

const validateData = (data) => {

    const response = []

    if (!data?.name?.length > 0)
        response.push(<Box>Digite o nome do agente marítimo.</Box>)

    if (response.length === 0)
        return ''

    return (response)
}

export const validateAddData = (data) => {
    const response = []

    if (!data?.import)
        response.push(<Box>Escolha a importação.</Box>)
    if (!data?.value)
        response.push(<Box>Digite o valor.</Box>)
    

    if (response.length === 0)
        return ''

    return (response)
}

export const validateDataParcels = (data) => {

    const response = []

    if (!data?.changes)
        response.push(<Box>Nenhuma mudança detectada.</Box>)
    if (data?.changes){
        const calcvalue = data.rows.map(e => Number(e.ValorPorImportacao)).reduce((x, y) => x + y, 0).toFixed(2)
        const origvalue = Number(data.Vlr).toFixed(2)
        const diff = calcvalue - origvalue
        if ( diff !== 0)
            response.push(<Box>O valor calculado difere da original em {diff.toFixed(2)}.</Box>)
        
    }

    if (response.length === 0)
        return ''

    return (response)
}

export default validateData
import { Box } from "@mui/material"

const validateData = (data) => {

    const response = []

    if (!data?.cod_country?.length > 0)
        response.push(<Box>Digite o Cod.país.</Box>)

    // if (!data?.additional_information?.length > 0)
    //     response.push(<Box>Digite a informação complementar.</Box>)

    if (!data?.origin_contry)
        response.push(<Box>Selecione um país.</Box>)

    if (response.length === 0)
        return ''

    return (response)
}

export default validateData


const apptheme = {
    primaryColor: '#3b714c',
    secondaryColor : '#FFFFFF',
    tertiaryColor : '#2f593c',
    quaternaryColor : '#ec9c05',
    quintenaryColor : '#44c485',
    senaryColor : '#339263',
    septenary : '#666666'

}



export default apptheme

import React, { useReducer, useContext } from 'react'
import { lockedReducer } from '../../Utils/reducers'
import { api } from '../../../services/api'
import ColorButton from '../../Buttons/ColorButton';
import DefaultModal from '../../Utils/DefaultModal';
import toast from 'react-hot-toast'
import { Box, Tooltip, TextField } from '@mui/material'
import validateData from './validateData';
import { externalComponent } from '../../AppRoutes';

import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';

const url = '/seguradora/'

const submit = async (data, getData, setLoader, handleClose) => {

    setLoader(true)
    let res = null

    if (data.id)
        res = await api.patch(`${url}${data.id}/`, data)
    else
        res = await api.post(url, data)
    if (res.status === 201 || res.status === 200) {
        toast.success(`Seguradora ${res.status === 201 ? 'adicionado' : 'editado'} com sucesso`)
        getData()
        handleClose()
        setLoader(false)
        return
    }
    toast.error(`Seguradora não pôde ser ${!data.id ? 'adicionado' : 'editado.'} Motivo: ${res.data}`)
    setLoader(false)
}

const getTitle = (data) => {
    if (data?.id)
        return 'Editar'
    return 'Adicionar'
}

const selectOptions = ["CIF", "CIF + Impostos"]

const EditSeguradora = ( {data, handleClose, reloadData} ) => {

    const { setLoader } = useContext(externalComponent)

    const [state, setState] = useReducer(lockedReducer, {
        name: '',
        collection_rate: '',
        calculation_basis: '',
        ...data,
        id: data.id ? data.id : 0
    })

    const validData = validateData(state)
    
    return(
        <>
        <DefaultModal
                title={
                    `${getTitle(state)} Seguradora`
                }
                handleClose={handleClose}
                content={
                    <>
                        <Box className='item-content-modal noborder' >

                            <Box className='item-profile-row'>
                                <TextField
                                    id="name"
                                    type="text"
                                    label="Nome"
                                    value={state.name}
                                    onChange={(e) => { setState({ 'name': e.target.value }) }}
                                    fullWidth={false}
                                    size='small'
                                    inputProps={{
                                        maxLength: 50
                                    }}
                                />

                            </Box>

                            <Box className='item-profile-row'>

                                <TextField
                                    id="collection_rate"
                                    type="text"
                                    label="Alíquota de cobrança"
                                    value={state.collection_rate}
                                    onChange={(e) => { setState({ 'collection_rate': e.target.value }) }}
                                    fullWidth={false}
                                    size='small'
                                    inputProps={{
                                        maxLength: 50
                                    }}
                                />  

                                <FormControl size='small'  >
                                    <InputLabel id="labelSeguradora">Base de cálculo</InputLabel>
                                        <Select
                                            labelId='labelSeguradora'
                                            id="calculation_basis"
                                            label="Base de cálculo"
                                            value={state.calculation_basis}
                                            onChange={(e) => { setState({ 'calculation_basis': e.target.value }) }}
                                            fullWidth={false}
                                                                            
                                            >
                                            {selectOptions.map((option, index) => (
                                                <MenuItem key={index} value={option}>{option}</MenuItem>
                                            ))}
                                        </Select>
                                </FormControl>

                            </Box>
                        
                        </Box>

                        
                    </>
                }
                action={
                    <>
                        <Tooltip placement="left" title={validData}>
                            <Box>
                                <ColorButton
                                    onClick={() => submit(state, reloadData, setLoader, handleClose)}
                                    disabled={validData}
                                >Salvar
                                </ColorButton>
                            </Box>
                        </Tooltip>
                    </>
                }
            />
        </>
    )
}

export default EditSeguradora;
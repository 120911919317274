import { Box } from "@mui/material"

const validateData = (data) => {

    const response = []

    if (!data?.name?.length > 0)
        response.push(<Box>Digite o nome do Terminal.</Box>)

    if (response.length === 0)
        return ''

    return (response)
}

export default validateData
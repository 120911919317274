import { Box } from "@mui/material"

const validateData = (data) => {

    const response = []

    if (!data?.name?.length > 0)
        response.push(<Box>Digite o nome do Terminal.</Box>)

    if (!data?.collection_rate?.length > 0)
        response.push(<Box>Informe a Alíquota de cobrança </Box>)

    if (!data?.calculation_basis?.length > 0)
        response.push(<Box>Selecione uma Base de cálculo.</Box>)

    if (response.length === 0)
        return ''

    return (response)
}

export default validateData
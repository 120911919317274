import { Box } from "@mui/material"

const validateData = (data) => {

    const response = []

    if (!data?.exporter)
        response.push(<Box>Selecione um Exportador.</Box>)

    if (!data?.order_date)
        response.push(<Box>Digite a data do pedido.</Box>)
    
    if (response.length === 0)
        return ''

    return (response)
}

export default validateData
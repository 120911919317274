import React, { useContext, useEffect, useState } from 'react'
import ColorButton from '../Buttons/ColorButton'
import apptheme from '../theme/default'
import { Box } from '@mui/material'
import '../Settings/styles.css'
import DefButton from '../Buttons/defbutton'
import ReplyIcon from '@mui/icons-material/Reply';
import { getSession } from '../../contexts/auth'
import { useWindowSize } from "@uidotdev/usehooks";
import { CalcWidthSize } from '../Settings'

import AnchorIcon from '@mui/icons-material/Anchor';
import WavesIcon from '@mui/icons-material/Waves';
import InventoryIcon from '@mui/icons-material/Inventory';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import DirectionsBoatIcon from '@mui/icons-material/DirectionsBoat';
import PostAddIcon from '@mui/icons-material/PostAdd';
import ApartmentIcon from '@mui/icons-material/Apartment';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import AssistantIcon from '@mui/icons-material/Assistant';
import AddToQueueIcon from '@mui/icons-material/AddToQueue';
import AccessibilityIcon from '@mui/icons-material/Accessibility';
import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety';
import PaidIcon from '@mui/icons-material/Paid';
import AddRoadIcon from '@mui/icons-material/AddRoad';

import Produtos from './Produtos'
import ShippingAgent from './Agente-maritimo'
import Armador from './Armador'
import Banco from './Banco'
import Porto from './Porto'
import InformationLI from './Informacao-LI'
import ShippingCompany from './ShippingCompany'
import Exporter from './Exporter'
import Brand from './Brand'
import Fabricator from './Fabricator'
import Terminal from './Terminal'
import Seguradora from './Seguradora'
import CustomsServices from './CustomsServices'
import DestinationExpenses from './DestinationExpenses'
import Frete from './Frete'
import { externalComponent } from '../AppRoutes'

const MenuList = () => {

    const user = getSession()
    const permissions = user?.permissions

    return ({
        'agente_maritimo': {
            title: 'Agente Marítimo',
            icon: (<WavesIcon />),
            obj: (<ShippingAgent />),
            permission: (permissions.view_shippingagent)
        },
        'armador': {
            title: 'Armador',
            icon: (<DirectionsBoatIcon />),
            obj: (<Armador />),
            permission: (permissions.view_armador)
        },
        'banco': {
            title: 'Banco',
            icon: (<AccountBalanceIcon />),
            obj: (<Banco />),
            permission: (permissions.view_bank)
        },
        'porto': {
            title: 'Porto',
            icon: (<AnchorIcon />),
            obj: (<Porto />),
            permission: (permissions.view_harbor)
        },
        'informacao_li': {
            title: 'INFORMAÇÕES COMPLEMENTARES LI',
            icon: (<PostAddIcon />),
            obj: (<InformationLI />),
            permission: (permissions.view_informationli)
        },
        'transportadora': {
            title: 'Transportadora',
            icon: (<LocalShippingIcon />),
            obj: (<ShippingCompany />),
            permission: (permissions.view_shippingcompany)
        },
        'exportador': {
            title: 'Exportador',
            icon: (<AccessibilityIcon />),
            obj: (<Exporter />),
            permission: (permissions.view_shippingcompany)
        },
        'marca': {
            title: 'Marca',
            icon: (<AssistantIcon />),
            obj: (<Brand />),
            permission: (permissions.view_brand)
        },
        'fabricante': {
            title: 'Fabricante',
            icon: (<ApartmentIcon />),
            obj: (<Fabricator />),
            permission: (permissions.view_brand)
        },
        'produto': {
            title: 'Produtos',
            icon: (<InventoryIcon />),
            obj: (<Produtos />),
            permission: (permissions.view_product)
        },
        'terminal': {
            title: 'Terminal',
            icon: (<AddToQueueIcon />),
            obj: (<Terminal />),
            permission: (permissions.view_terminal)
        },
        'seguradora': {
            title: 'Seguradora',
            icon: (<HealthAndSafetyIcon />),
            obj: (<Seguradora />),
            permission: (permissions.view_insurancecompany)
        },
        'customsservices': {
            title: 'SERVIÇOS ALFANDEGÁRIOS',
            icon: (<PaidIcon />),
            obj: (<CustomsServices />),
            permission: (permissions.view_customsservices)
        },
        'destinationexpenses': {
            title: 'Despesas Destino',
            icon: (<AddRoadIcon />),
            obj: (<DestinationExpenses />),
            permission: (permissions.view_destinationexpenses)

        },
        'frete': {
            title: 'Frete',
            icon: (<LocalShippingIcon />),
            obj: (<Frete />),
            permission: (permissions.view_frete)

        }
        
    })
}

const Cadastros = () => {
    const {isMobile} = useContext(externalComponent)
    const [selectedTab, setSelectedTab] = useState()
    const size = useWindowSize();

    const menulist = MenuList()

    const calcSize = CalcWidthSize(size)

    const itens = Object.entries(menulist).filter(([key, value]) => value.permission)

    useEffect(()=>{
        window.backtobase = () => setSelectedTab()
    }, [])
    
    return (
        <>
            {!selectedTab ?
                <>
                    <Box className='gridBox' sx={{ gridTemplateColumns: `repeat(${calcSize}, minMax(0, 1fr))` }}>
                        {itens.map(([key, value]) => (
                            <ColorButton
                                onClick={() => setSelectedTab(key)}
                                sx={{ height: '8rem', flex: 1 , visibility: !value.obj ? 'hidden' : 'visible' }}
                                fColor={apptheme.primaryColor}
                                sColor={apptheme.tertiaryColor}>
                                <Box className='flexbox'>
                                    {value.icon}
                                    {value.title}
                                </Box>
                            </ColorButton>

                        ))}
                    </Box>
                </>
                :
                <>
                    <Box className={`settings-back-button ${isMobile ? '': 'hidden'}`}>
                        <DefButton
                            onClick={() => setSelectedTab()}
                        >
                            <ReplyIcon />
                        </DefButton>
                    </Box>
                    {MenuList()[selectedTab].obj}
                </>
            }

        </>
    )
}

export default Cadastros
import React, { useReducer, useContext } from 'react'
import { lockedReducer } from '../../Utils/reducers'
import { api } from '../../../services/api'
import ColorButton from '../../Buttons/ColorButton';
import DefaultModal from '../../Utils/DefaultModal';
import toast from 'react-hot-toast'
import { Box, Tooltip, TextField } from '@mui/material'
import validateData from './validateData';
import { externalComponent } from '../../AppRoutes';

const url = '/produto/'

const getTitle = (data) => {
    if (data?.id)
        return 'Editar'
    return 'Adicionar'
}

const submit = async (data, getData, setLoader, handleClose) => {

    setLoader(true)
    let res = null

    if (data.id)
        res = await api.patch(`${url}${data.id}/`, data)
    else
        res = await api.post(url, data)
    if (res.status === 201 || res.status === 200) {
        toast.success(`Produto ${res.status === 201 ? 'adicionado' : 'editado'} com sucesso`)
        getData()
        handleClose()
        setLoader(false)
        return
    }
    toast.error(`Produto não pôde ser ${!data.id ? 'adicionado' : 'editado.'} Motivo: ${res.data}`)
    setLoader(false)
}

const EditProductModal = ({ data, handleClose, reloadData, unitys }) => {

    const { setLoader } = useContext(externalComponent)

    const [state, setState] = useReducer(lockedReducer, {
        codpro: '',
        description: '',
        highlight_ncm: '',
        nve: '',
        inmetro_certificate: '',
        reg_orquestra: '',
        family: '',
        type: '',
        unity: '',
        tax_regime: '',
        specification: '',
        legal_substantiation: '',
        ...data,
        id: data.id ? data.id : 0,
        equipment: data.equipment || '',
    })

    const [helpers, setHelpers] = useReducer(lockedReducer, {
        loaded_equipments: []
    })

    const validData = validateData(state)

    return (
        <>
            <DefaultModal
                title={
                    `${getTitle(state)} Produto`
                }
                handleClose={handleClose}
                content={
                    <>
                        <Box className='item-content-modal noborder' >

                            <Box className='item-profile-row'>
                                <TextField
                                    id="codpro"
                                    type="text"
                                    label="Codpro"
                                    value={state.codpro}
                                    onChange={(e) => { setState({ 'codpro': e.target.value }) }}
                                    fullWidth={false}
                                    size='small'
                                    inputProps={{
                                        maxLength: 50
                                    }}
                                />

                                <TextField
                                    id="description"
                                    type="text"
                                    label="Descrição"
                                    value={state.description}
                                    onChange={(e) => { setState({ 'description': e.target.value }) }}
                                    fullWidth={false}
                                    size='small'
                                    inputProps={{
                                        maxLength: 50
                                    }}
                                />

                            </Box>

                            <Box className='item-profile-row'>
                                <TextField
                                    id="highlight_ncm"
                                    type="text"
                                    label="Destaque NCM"
                                    value={state.highlight_ncm}
                                    onChange={(e) => { setState({ 'highlight_ncm': e.target.value }) }}
                                    fullWidth={false}
                                    size='small'
                                    inputProps={{
                                        maxLength: 50
                                    }}
                                />

                                <TextField
                                    id="nve"
                                    type="text"
                                    label="NVE"
                                    value={state.nve}
                                    onChange={(e) => { setState({ 'nve': e.target.value }) }}
                                    fullWidth={false}
                                    size='small'
                                    inputProps={{
                                        maxLength: 50
                                    }}
                                />

                            </Box>

                            <Box className='item-profile-row'>
                                <TextField
                                    id="inmetro_certificate"
                                    type="text"
                                    label="Certif. Inmetro"
                                    value={state.inmetro_certificate}
                                    onChange={(e) => { setState({ 'inmetro_certificate': e.target.value }) }}
                                    fullWidth={false}
                                    size='small'
                                    inputProps={{
                                        maxLength: 50
                                    }}
                                />

                                <TextField
                                    id="reg_orquestra"
                                    type="text"
                                    label="Reg. Orquestra"
                                    value={state.reg_orquestra}
                                    onChange={(e) => { setState({ 'reg_orquestra': e.target.value }) }}
                                    fullWidth={false}
                                    size='small'
                                    inputProps={{
                                        maxLength: 50
                                    }}
                                />

                            </Box>

                            <Box className='item-profile-row'>
                                <TextField
                                    id="family"
                                    type="text"
                                    label="Família"
                                    value={state.family}
                                    onChange={(e) => { setState({ 'family': e.target.value }) }}
                                    fullWidth={false}
                                    size='small'
                                    inputProps={{
                                        maxLength: 50
                                    }}
                                />

                                <TextField
                                    id="type"
                                    type="text"
                                    label="Tipo"
                                    value={state.type}
                                    onChange={(e) => { setState({ 'type': e.target.value }) }}
                                    fullWidth={false}
                                    size='small'
                                    inputProps={{
                                        maxLength: 50
                                    }}
                                />

                            </Box>

                            <Box className='item-profile-row'>
                                <TextField
                                    id="unity"
                                    type="text"
                                    label="Unidade"
                                    value={state.unity}
                                    onChange={(e) => { setState({ 'unity': e.target.value }) }}
                                    fullWidth={false}
                                    size='small'
                                    inputProps={{
                                        maxLength: 50
                                    }}
                                />

                                <TextField
                                    id="tax_regime"
                                    type="text"
                                    label="Regime de Tributação"
                                    value={state.tax_regime}
                                    onChange={(e) => { setState({ 'tax_regime': e.target.value }) }}
                                    fullWidth={false}
                                    size='small'
                                    inputProps={{
                                        maxLength: 50
                                    }}
                                />

                            </Box>

                            <Box className='item-profile-row'>
                                <TextField
                                    id="specification"
                                    type="text"
                                    label="Especificação"
                                    value={state.specification}
                                    onChange={(e) => { setState({ 'specification': e.target.value }) }}
                                    fullWidth={false}
                                    size='small'
                                    inputProps={{
                                        maxLength: 50
                                    }}
                                />

                                <TextField
                                    id="legal_substantiation"
                                    type="text"
                                    label="Fundamentação Legal"
                                    value={state.legal_substantiation}
                                    onChange={(e) => { setState({ 'legal_substantiation': e.target.value }) }}
                                    fullWidth={false}
                                    size='small'
                                    inputProps={{
                                        maxLength: 50
                                    }}
                                />

                            </Box>


                        </Box>
                    </>
                }
                action={
                    <>
                        <Tooltip placement="left" title={validData}>
                            <Box>
                                <ColorButton
                                    onClick={() => submit(state, reloadData, setLoader, handleClose)}
                                    disabled={validData}
                                >Salvar
                                </ColorButton>
                            </Box>
                        </Tooltip>
                    </>
                }
            />
        </>
    )
}

export default EditProductModal
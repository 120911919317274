const Header = () => {
    return (
        <section>
            <div className="text-center">
                {/* <img alt='v10' src="/static/BKS.png" /> */}
                <h1>SICI</h1>
            </div>
        </section>
    );
};

export default Header;
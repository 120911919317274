import { Box } from "@mui/material"

const validateData = (data) => {
    const response = []

    if (!data?.name?.length > 0)
        response.push(<Box>Digite o nome do Serviço Alfandegário.</Box>)

    if (!data?.value > 0)
        response.push(<Box>Informe o valor </Box>)

    if (!data?.validity > 0)
        response.push(<Box>Informe a validade </Box>)

    if (!data?.collection_criteria > 0)
        response.push(<Box>Selecione uma Base de cálculo.</Box>)

    if (response.length === 0)
        return ''

    return (response)
}

export default validateData